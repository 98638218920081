/* eslint-disable */
var icon = require('vue-svgicon');
icon.register({
    'ban-fill': {
        width: 16,
        height: 16,
        viewBox: '0 0 16 16',
        data: '<path pid="0" d="M16 8A8 8 0 110 8a8 8 0 0116 0M2.71 12.584q.328.378.706.707l9.875-9.875a7 7 0 00-.707-.707l-9.875 9.875z"/>',
    },
});
